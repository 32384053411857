<template>
  <div class="container">

    <transition name="fade">
      <button v-show="showButton" @click="togglePhone">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
          <path d="M0 0h24v24H0z" fill="none"/>
          <path
              d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"/>
        </svg>
      </button>
    </transition>

    <transition name="slide">
      <span class="container__slider" v-if="showPhone">
        <a href="mailto:victor.hodobay@gmail.com"> email  me</a>
  </span>
    </transition>

  </div>
</template>

<script>
export default {
  name: "newContact",
  data() {
    return {
      showPhone: false,
      showButton: true
    }
  },
  methods: {
    hideSlide() {
      setTimeout(() => {
        this.showButton = !this.showButton

        this.showPhone = !this.showPhone
      }, 5000)
    },
    togglePhone() {
      this.showButton = !this.showButton
      setTimeout(() => {
        this.showPhone = !this.showPhone
        this.hideSlide()
      }, 300)
    }
  }
}
</script>

<style scoped lang="scss">

.container {
  display: none;
  position: fixed;

  @media screen and (min-width: 40em) {
    top: 50vh;
    right: 0;
    z-index: 3;
    display: flex;
    border-radius: 1rem 0 0 1rem;
    align-items: center;

    &__slider {
      box-shadow: var(--shadow-small);
      background: black;
      border-radius: 8rem 0 0 8rem;
      opacity: .7;
      padding: 4rem 2rem;

      a {
        color: white;
        text-shadow: var(--shadow-smaller);
      }
    }

    button {
      padding: 4rem 1.3rem 4rem 1.3rem;
      border-radius: 8rem 0 0 8rem;
      border: none;
      box-shadow: var(--shadow-small);
    }
    &:hover {
      transform: scale(1.05);
      transition: .2s ease;

    }
  }
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(400px);
}

.slide-enter-active,
.slide-leave-active {
  transition: all 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


.fade-enter-active {
  transition: all .3s linear 1.6s;
}

.fade-leave-active {
  transition: all .3s;
}

</style>
